import React from 'react';
import { Link } from 'react-router-dom';
import Columns from 'react-bulma-components/lib/components/columns';
import Intro from '../intro/Intro';
import logo from '../../../img/ar-logo.png';

function SearchHeader() {
  return (
    <header>
      <Columns className="">
        <div className="column is-8 is-offset-2">
          <Link className="logo-wrap" to="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
        </div>
        <div className="column is-8 is-offset-2">
          <Intro />
        </div>
      </Columns>
    </header>
  );
}

export default SearchHeader;
