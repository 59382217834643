import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';
import logo from '../../../img/ar-logo.png';

function ResultsHeader() {
  const [search] = useState(window.location.hash.substring(window.location.hash.indexOf('?')));
  return (
    <header>
      <Columns className='columns'>
        <div className='column'>
          <div className='logo'>
            <Link to='/'>
              <img src={logo} alt='' />
            </Link>
          </div>
        </div>
        <div className='column is-narrow'>
          <div className='extraflextra'>
            <div className='flex mt-4'>
              <div className=''>
                <Button.Group className={'has-addons'}>
                  <Button>
                    <Link to='/'>New search</Link>
                  </Button>
                  <Button>
                    <Link to={'/' + (search ? search : '')}>Edit query</Link>
                  </Button>
                </Button.Group>
              </div>
              <div className=''>
                <a className='button is-info is-light ml-3' href='https://forms.office.com/Pages/ResponsePage.aspx?id=Bm5ROwIhzUCIvIv1b3-AFSceHGFWjA1JpABK8HibokVUMFJVN1dPOURaWEpCQkdaNEtLT1g4TUJVMCQlQCN0PWcu' target='_blank' rel='noopener noreferrer'>
                  Feedback
                </a>
              </div>
            </div>
          </div>
        </div>
      </Columns>
    </header>
  );
}

export default ResultsHeader;
