import React, { useState, useEffect } from 'react';
import Button from 'react-bulma-components/lib/components/button';
import ModalReport from '../modal/modalReport';
import Keywords from '../keywords/Keywords';
import InputCheckbox from '../input/inputCheckbox';

export default function Paragraphs(props) {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  useEffect(() => {
    setData({
      Id: props.paragraph.Id,
      Title: props.title,
      TextParagraph: props.paragraph.TextParagraph,
      Keywords: props.paragraph.KeyWordInContext,
      Link: props.link,
      PublicationDate: props.publicationDate,
      Authors: props.authors,
      Taxonomy: props.taxonomy,
      Abstract: props.abstract,
    });
  }, [props]);
  const open = () => {
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };
  return (
    <div className='resitm__section' key={props.paragraph.Id}>
      <p>
        <strong>Paragraph:</strong> {props.paragraph.TextParagraph}
      </p>
      {props.paragraph.KeyWordInContext && (
        <Keywords
          paragraph={props.paragraph}
          data={data}
          domain={props.domain}
        />
      )}
      <div className='resitm__actions'>
        <div className='resitm__select'>
          <InputCheckbox
            label={'Select for download'}
            id={props.paragraph.Id}
            name={'selected'}
            value={props.paragraph.Id}
            onChange={props.toggleItem}
          />
        </div>
        <div className='resitm__report'>
          <Button onClick={open}>Report data error</Button>
          <ModalReport
            show={show}
            onClose={close}
            data={{
              Id: props.paragraph.Id,
              Title: props.title,
              TextParagraph: props.paragraph.TextParagraph,
              Keywords: props.paragraph.KeyWordInContext,
              Link: props.link,
              PublicationDate: props.publicationDate,
              Authors: props.authors,
              Taxonomy: props.taxonomy,
              Abstract: props.abstract,
            }}
          />
        </div>
        <hr />
      </div>
    </div>
  );
}
