import { SearchParamsToJSON } from './searchParamsToJSON';
require('es6-promise').polyfill();

export const SendLog = async (opt) => {
  const data = base(opt);
  const options = {
    url: 'https://3a95f29cc0b1417d99966d0706639d3a.europe-west3.gcp.cloud.es.io:9243/api_logs/_doc',
    data: data,
  };
  return send(options)
    .then(() => {
      return true;
    })
    .catch(() => {
      throw new Error('Something went wrong');
    });
};

function send(options) {
  console.log('send options', options);
  return new Promise((resolve, reject) => {
    if (options.data) {
      const REST_ELASTIC_CLOUD_API_ID = 'WTTaw3ABqhuYnFsyG8NK';
      const REST_ELASTIC_CLOUD_API_KEY = 'DiaJmspKTiKNIH4rHYc7Vg';
      fetch(options.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'ApiKey ' + btoa(unescape(encodeURIComponent(REST_ELASTIC_CLOUD_API_ID + ':' + REST_ELASTIC_CLOUD_API_KEY))),
        },
        body: JSON.stringify(options.data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    }
  });
}

function base(opt) {
  const obj = {
    action: opt.action,
    project: 'passage-retrieval-website',
    route: window.location.pathname,
    user: 'anonymous',
    search: SearchParamsToJSON(window.location.hash.substring(window.location.hash.indexOf('?')).substring(1)),
    status: 200,
    server: process.env.NODE_ENV === 'production' ? 'live' : 'dev',
    version: '1.0.0',
    timestamp: new Date().getTime(),
    data: opt.data,
  };
  return obj;
}

export default { SendLog };
