import React, { useState, useEffect } from 'react';
import Box from 'react-bulma-components/lib/components/box';
import { SendLog } from '../../utils/log';
import { toast } from 'react-toastify';
import Info from '../info/Info';
import Icon from 'react-bulma-components/lib/components/icon';
import Items from './Items';
import Feedback from '../feedback/Feedback';
import Paragraphs from './Paragraphs';

export default function Resitm({ item, paragraph, toggleItem, search }) {
  const [isShown, setIsShown] = useState(false);
  const [data, setData] = useState({});
  const [domain, setDomain] = useState('');

  useEffect(() => {
    setData({
      Id: item.Title.replace(' ', '-'),
      Title: item.Title,
      Link: item.Link,
      PublicationDate: item.PublicationDate,
      Authors: item.Authors,
      Taxonomy: item.Taxonomy,
      Abstract: item.Abstract,
    });
    setDomain(getSearchValue(search, 'domain'));
  }, [item, paragraph, search]);

  const getSearchValue = (search, key) => {
    const first = search.indexOf(key);
    const last =
      search.indexOf('&', first) === -1
        ? search.length
        : search.indexOf('&', first);
    const pair = search.substring(first, last);
    return pair.split('=')[1];
  };
  const moreInfo = () => {
    console.log('moreInfo');
    if (!isShown) {
      const opt = {
        action: 'more info',
        data: {
          Id: data.Id,
        },
      };
      SendLog(opt)
        .then((data) => {
          console.log(
            'Data from SendLog() with async( When promise gets resolved ): ' +
              data
          );
        })
        .catch((error) => {
          console.log(
            'Error from SendLog() with async( When promise gets rejected ): ' +
              error
          );
        });
    }
    setIsShown(!isShown);
  };

  const feedback = (e, positive, keyword) => {
    if (e.currentTarget.disabled) {
      return false;
    }
    e.currentTarget.setAttribute('disabled', 'disabled');
    const opt = {
      action: positive ? 'positive' : 'negative',
      data: {
        Id: data.Id,
        TextParagraph: data.TextParagraph,
        Keywords: data.Keywords,
        Taxonomy: data.Taxonomy,
        Selected: keyword,
      },
    };
    toast.info('Thank you for your feedback!');
    SendLog(opt)
      .then((data) => {
        console.log(
          'Data from SendLog() with async( When promise gets resolved ): ' +
            data
        );
      })
      .catch((error) => {
        console.log(
          'Error from SendLog() with async( When promise gets rejected ): ' +
            error
        );
      });
  };

  const extraInfo = (heading, data) => {
    return (
      <div className='resitm__text'>
        <strong>{heading}:</strong>{' '}
        {data && (
          <>
            <Feedback keyword={heading} feedback={feedback} tight={true} />{' '}
            {data}
          </>
        )}
      </div>
    );
  };

  return (
    <Box>
      <article className='resitm'>
        <div className='resitm__content'>
          <h1 className='resitm__title'>{item.Title}</h1>
          <p className='resitm__paragraph'>{item.Abstract}</p>
          <strong>Link:</strong>{' '}
          <a
            className='resitm__link'
            href={item.Link}
            target='_blank'
            rel='noopener noreferrer'
          >
            {item.Link}
          </a>
          {item.Paragraphs.length > 0 && (
            <div className='resitm__info'>
              <button
                className='resitm__info-action'
                onClick={moreInfo}
                aria-expanded={isShown}
              >
                More info
                <Icon icon='angle-down' />
              </button>
              <Info isShown={isShown}>
                <div className=''>
                  {item.PublicationDate &&
                    !isNaN(new Date(item.PublicationDate)) && (
                      <div>
                        <strong>Year:</strong>{' '}
                        {new Date(item.PublicationDate).getFullYear()}
                      </div>
                    )}
                  {item.Authors && (
                    <Items text={'Authors'} list={item.Authors} />
                  )}
                  {item.Taxonomy && (
                    <Items text={'Taxonomy'} list={item.Taxonomy} />
                  )}
                  {item.Affiliation && (
                    <Items text={'Affiliation'} list={item.Affiliation} />
                  )}
                  {domain === 'greenplastics' &&
                    extraInfo('DLSummary', item.DLSummary)}
                  {domain === 'greenplastics' &&
                    extraInfo('Texttiling', item.Texttiling)}
                  {domain === 'greenplastics' &&
                    extraInfo('QueryFormulation', item.QueryFormulation)}
                    
                  {item.Paragraphs.map((paragraph) => {
                    return (
                      <Paragraphs
                        paragraph={paragraph}
                        title={item.Title}
                        link={item.Link}
                        publicationDate={item.PublicationDate}
                        authors={item.Authors}
                        taxonomy={item.Taxonomy}
                        abstract={item.Abstract}
                        domain={domain}
                        toggleItem={toggleItem}
                        key={paragraph.Id}
                      />
                    );
                  })}
                </div>
              </Info>
            </div>
          )}
        </div>
      </article>
    </Box>
  );
}
