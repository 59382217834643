import React from 'react';
import Icon from 'react-bulma-components/lib/components/icon';
import './_feedback.scss';

function Feedback({ keyword, feedback, tight }) {
  return (
    <div
      className='feedback'
      style={
        tight ? { position: 'relative', width: '48px', height: '24px' } : {}
      }
    >
      <button
        type='button'
        onClick={(e) => {
          feedback(e, true, keyword);
        }}
        style={
          tight
            ? {
                position: 'absolute',
                top: '6px',
                transform: 'rotate(180deg)',
              }
            : { transform: 'rotate(180deg)' }
        }
      >
        <Icon icon='angle-down' color='success' />
      </button>
      <button
        type='button'
        onClick={(e) => {
          feedback(e, false, keyword);
        }}
        style={tight ? { position: 'absolute', top: '6px', left: '24px' } : {}}
      >
        <Icon icon='angle-down' color='danger' />
      </button>
    </div>
  );
}

export default Feedback;
