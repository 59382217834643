import React, { useState, useEffect } from 'react';
import Loading from '../loading/Loading';
import Resitm from './Resitm';
import './_resultslist.scss';

export default function ResultsList({ data, toggleItem, search }) {
  const [currentData, setCurrentData] = useState([]);
  useEffect(() => {
    setCurrentData(data);
  }, [currentData, data]);
  const display = (currentData) => {
    if (currentData === null) {
      return (
        <div>
          The search result set is empty, please reformulate your query.
          <br />
          Note that the data collections in this demo are a sample of a larger
          data set.
        </div>
      );
    } else if (currentData.length === 0) {
      return <Loading />;
    } else if (typeof currentData === 'string') {
      return (
        <div>Some error has occurred. Please contact us if it persists.</div>
      );
    } else if (currentData.length > 0) {
      return currentData.map((item, index) => {
        return (
          <div key={index}>
            <div className='resultslist__item'>
              <Resitm item={item} toggleItem={toggleItem} search={search} />
            </div>
          </div>
        );
      });
    }
  };
  return <div className={'resultslist'}>{display(currentData)}</div>;
}
